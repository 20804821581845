import React, { useState } from "react";
import { Button, Modal, Form, Input, Row, Col, message, Space, Switch, Select ,Popconfirm} from "antd";
import axios from "axios";
const { Option } = Select;

const EditPara = ({ mydata, deviceCode }) => {
    const [form] = Form.useForm();
    const[zonevalue,setZonevalue]=useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onFinish = (values) => {
       function checkNumber(set_val, measurement) {
            let set_value;
            if (measurement === 'temperature' || measurement === 'current') {
                set_value = set_val * 10
            }
            else if (measurement === 'misc') {
                set_value = set_val * 10
                // set_value = parseInt(set_val);
            }
            else if (measurement === 'event') {
                if (set_val === true) {
                    set_value = parseInt(1)
                }
                else {
                    set_value = parseInt(0)
                }
            }
            else {
                set_value = set_val;
            }
            return set_value;
        }
        axios.post("/outputs/set", {
            "device_code": deviceCode,
            "zone": values.zone,
            "method": "on",
            "options":
            {
                "cold_rush": checkNumber(values.value, values.measurement)
                // checkNumber(parseInt(values.value))
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.is_output_set === true) {
                        message.success("output Updated Successfully");
                        setIsModalVisible(false);
                        setTimeout(function () { window.location = window.location; }, 15000);// reload page after 15 sec
                    }
                    else {
                        message.error(res?.data?.message)
                    }
                }
                else {
                    message.error("Something went wrong!")
                }
            })
            .catch((error) => {
                message.error(error)
            })
    }
    const onFinishFailed = () => {
        message.error("Missing Value");
    }
    const text = 'Are you sure want to set this point?';
    return (
        <>
            <Button type="primary" onClick={showModal}>
                Edit
            </Button>
            <Modal title="Set Controller Points" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <Row justify="left" style={{ maxWidth: 500 }}>
                    <Col span={20}>
                        <Form name='frmstore'
                            form={form}
                            initialValues={mydata}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item label="Name"
                                name="name"
                                wrapperCol={{
                                    span: 20,
                                }}
                            >
                                <Input name="name" disabled />
                            </Form.Item>
                            <Form.Item label="Measurement"
                                name="measurement"
                                wrapperCol={{
                                    span: 20,
                                }}
                            >
                                <Input name="measurement" disabled />
                            </Form.Item>
                            <Form.Item label="Zone"
                                name="zone"
                                wrapperCol={{
                                    span: 20,
                                }}>
                                <Input name="zone" disabled />
                            </Form.Item>
                            <Form.Item label="Value"
                                name="value"
                                onChange={e => setZonevalue(e.target.value)}
                                wrapperCol={{
                                    span: 20,
                                }}>
                                {
                                    mydata.measurement === 'event' ? <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked={mydata.value} name="value" /> : <Input name="value" />
                                }
                            </Form.Item>
                            <Form.Item label="Min Value"
                                name="minvalue"
                                wrapperCol={{
                                    span: 20,
                                }}>
                                <Input name="minvalue" disabled />
                            </Form.Item>
                            <Form.Item label="Max Value"
                                name="maxvalue"
                                wrapperCol={{
                                    span: 20,
                                }}>
                                <Input name="maxvalue" disabled />
                            </Form.Item>
                            <Form.Item label=""
                                wrapperCol={{
                                    offset: 8,
                                    span: 20,
                                }}
                            >
                                <Space>
                                    <Popconfirm placement="topLeft" title={text} 
                                    onConfirm={() => {
                                        form.submit();
                                      }}
                                      onCancel={handleCancel}
                                    okText="Yes" cancelText="No">
                                    <Button type="primary" htmlType='submit' >Set </Button>
                                    </Popconfirm>
                                 <Button type="danger" onClick={handleCancel}>Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default EditPara;