import React, { useState } from 'react';
import {
  DashboardOutlined
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import Test from './Test';
const { Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Dashboard', '1', <DashboardOutlined />),
 ];
const MainPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" >
        <img src="logo192.png" height="50px" width="50px" style={{padding:"15"}} />
        </div>
        <br></br>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 20,
              minHeight: "100%",
            }}
          >
            <Test/>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            fontWeight: "bold",
            position:"fixed",
            bottom:"0px",
            display:"flex",
            justifyContent:"center",
            width:"100%",
            height:"5%"
            
          }}
        >
          <a href="https://smartiam.in/" target="_blank"> © IAM 2022. All rights reserved Integreted Active Monitoring</a>
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainPage;