import React, { useEffect, useState, useRef } from 'react';
import 'antd/dist/antd.css';
import { Space, Table, Button, Spin, message, Typography, Switch ,Row,Col, Card, Divider} from 'antd';
import axios from 'axios';
import EditPara from './EditPara';
import RangeData from "../Common/RangeData.json"
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useSearchParams } from 'react-router-dom';
import { Form, Input } from 'antd';
const { Title } = Typography;


const Test = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams(undefined);
  const deviceCode = searchParams.get("d_c");
  const [data, setData] = useState([]);
  const [PData,setPdata]=useState({});
  const [cur_para, setCurpara] = useState();
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [power_status, setPower] = useState();
  const [defrost_status, setDefrost] = useState()
 //  function to get compressor name
  function getcompressorName(searchParams) {
    let name = ""
    var match = searchParams.get("d_c")?.match(/(\d+)/);
    return <Title level={4} style={{ overflow: "hidden", inlineSize: "300px" }}>{name = "Compressor" + " " + match?.[0]}</Title>
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  let writabledata = []

  function getEvenModDevice(deviceCode)
  {
    let dname;
    let mayu=deviceCode.slice(-2)
    console.log(mayu)
    if(deviceCode==='MOD01')
    {
      dname="MOD02"
    }
    else if(deviceCode==='MOD03')
    {
      dname="MOD04"
    }
    else if(deviceCode==='MOD05')
    {
      dname="MOD06"
    }
    else if(deviceCode==='MOD07')
    {
      dname="MOD08"
    }
    else if(deviceCode==='MOD09')
    {
      dname="MOD10"
    }
    else if(deviceCode==='MOD11')
    {
      dname="MOD12"
    }
    return dname

  }

    useEffect(()=>
  {
    axios.get(`/devices/modbus/status?device_code=${deviceCode}`)
    .then((res)=>
    {
      setLoading(false)
      let pdata=[];
      let i=1;
      res?.data?.inputs?.map((abc, i) => {
        return (
          pdata.push(
            {
              "zone":abc.tags.zone,
              "measurement":abc.measurement,
              "name": abc.tags.name,
              "value":  abc.fields.value,
              "minvalue":getminData(abc.tags.zone),
              "maxvalue":getmaxData(abc.tags.zone),
              "id":i++
            }
          )
        )
      })
      setData(pdata);
      console.log("Maaaaa",pdata)
     })
    .catch((error)=>
    {
      message.error("NO Data For"+ `${searchParams.get("d_c")}`)
      setLoading(false)
    })

  },[])
  let flag = false;
  /*Get data for table we are getting only writable parameter*/
  data.map((abc, i) => {
    if (abc.zone == "P2") {
      flag = true;
    }
    if (flag) {
      return (
        writabledata.push(
          {
            "zone": abc.zone,
            "measurement": abc.measurement,
            "name": abc.name,
            "value": abc.value,
            "minvalue": getminData(abc.zone),
            "maxvalue": getmaxData(abc.zone)
          }
        )
      )

    }
  })
  

  function getminData(cur_para) {
    let minvalue;
    for (let i = 0; i < RangeData.length; i++) {
      if (RangeData[i].parameter === cur_para) {
        minvalue = RangeData[i].min
        break
      }
    }
    console.log(minvalue)

    return minvalue;

  }
  function getmaxData(cur_para) {
    let maxvalue;
    for (let i = 0; i < RangeData.length; i++) {
      if (RangeData[i].parameter === cur_para) {
        maxvalue = RangeData[i].max
        break

      }
    }
    console.log(maxvalue)

    return maxvalue;

  }
  let testdata={};
useEffect(()=>
{
 axios.get(`https://api.smart-iam.com/api/gateway/cold-rush-api/devices/modbus/status?device_code=${getEvenModDevice(deviceCode)}`)
    .then((res)=>
    {
    res?.data?.inputs?.map((mm) => {
        testdata[mm.tags?.zone] = mm.fields?.value
    })
    setLoading1(false)
    setPdata(testdata);
      console.log("MyData",testdata)
     })
    .catch((error)=>
    {
      message.error("NO Data For"+ `${searchParams.get("d_c")}`)
      setLoading1(false)
      
    })


},[])




  const columns = [
    {
      title: <b>Zone</b>,
      dataIndex: 'zone',
      key: 'zone',
      ...getColumnSearchProps('zone'),
      // render: (text) => <a>{text}</a>,
    },
    {
      title: <b>Name</b>,
      dataIndex: 'name',
      key: 'name',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: <b>Min value</b>,
      dataIndex: 'minvalue',
      key: 'minvalue',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: <b>Max Value</b>,
      dataIndex: 'maxvalue',
      key: 'maxvalue',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: <b>Current Value</b>,
      dataIndex: 'value',
      key: 'value',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: <b>Action</b>,
      key: 'action',
      render: (_, record) => (
        <>
          {
            setCurpara(record.zone)
          }
          <EditPara mydata={record} deviceCode={deviceCode} />
        </>

      ),
    },
  ];
const onPowerDataFinish1 = values => {
   function abc1(value)
   {
     let val;
     if (value==true)
     {
       val=1
     }
     else
     {
       val=0;
     }
     return val;
   }
    // if (values.password1 === "iam") {
      axios
          .post("/outputs/set", {
            "device_code": getEvenModDevice(deviceCode),
            "zone": "ACSTATE",
             "method": "on",
            "options":
            {
                "cold_rush": abc1(values.ACSTATE) 
                // values.ACSTATE
            }
        })
          .then(res => {
            if (res.status === 200) {
              if (res.data.is_output_set === true) {
                  message.success("output Updated Successfully");
                  setTimeout(function () { window.location = window.location; }, 15000);// reload page after 15 sec
              }
              else {
                  message.error(res?.data?.message)
              }
          }
          else {
              message.error("Something went wrong!")
          }
          })
          .catch(function (error) {
              console.log(error);
              message.error("Backed Server not responding");
          });
  // } else {
  //     message.error("Password is incorrect");
  // }
};
const onDefrostDataFinish = values => {
  function abc(value)
  {
    let val;
    if (value==true)
    {
      val=1
    }
    else
    {
      val=0;
    }
    return val;
  }
//  if (values.password2 === "iam") {
      axios
          .post("/outputs/set", {
            "device_code": getEvenModDevice(deviceCode),
            "zone":"DEFROSTSTATE",
            "method": "on",
            "options":
            {
                "cold_rush":   abc(values.Defrost)
                // values.Defrost == 'true' ?val=1:val=0
            }
        })
          .then(res => {
            if (res.status === 200) {
              if (res.data.is_output_set === true) {
                  message.success("output Updated Successfully");
                  setTimeout(function () { window.location = window.location; }, 15000);// reload page after 15 sec
              }
              else {
                  message.error(res?.data?.message)
              }
          }
          else {
              message.error("Something went wrong!")
          }
          })
          .catch(function (error) {
              console.log(error);
              message.error("Backed Server not responding");
          });
  // } else {
  //     message.error("Password is incorrect");
  // }
};
const onFinishFailed = errorInfo => {
  console.log("Failed:", errorInfo);
  message.error("Opps! Something went wrong");
};

  return (
    <>
      <Space> {getcompressorName(searchParams)} <Title level={3} style={{ overflow: "hidden", inlineSize: "300px" }}>Controller Set Points</Title></Space>
      <Space>
        {
          loading1?<Spin size="large" tip="Please Wait Loading..."></Spin>
          :<>
          <Form
         name="POWER"
         layout="inline"
         form={form1}
         initialValues={PData}
         onFinish={onPowerDataFinish1}
         onFinishFailed={onFinishFailed}
         autoComplete="off"
       >
         <Form.Item
           name="ACSTATE"
D           label={<h1 style={{ fontWeight: "bold", marginTop: "10px" }}>Power</h1>}
         >
           <Switch 
           name="ACSTATE"
           defaultChecked ={PData?.ACSTATE} 
          checkedChildren="ON" 
          unCheckedChildren="OFF"
             // style={{ backgroundColor: PData.ACSTATE == 1 ? 'green' : 'red' }}
           />
         </Form.Item>
         {/* <Form.Item
           label="Password"
           name="password1"
           rules={[
             {
               required: true,
               message: 'Please enter your password!',
             },
           ]}
         >
           <Input.Password  />
         </Form.Item> */}
         <Form.Item>
           <Button type="primary" htmlType="submit">
             SET
           </Button>
         </Form.Item>
       </Form>
       <Form
         name="DEFROST"
         layout="inline"
         form={form}
         initialValues={PData}
         onFinish={onDefrostDataFinish}
         onFinishFailed={onFinishFailed}
         autoComplete="off"
       >
         <Form.Item
           name="Defrost"
           label={<h1 style={{ fontWeight: "bold", marginTop: "10px" }}>Defrost</h1>}
         >
           <Switch 
           checkedChildren="ON" 
           unCheckedChildren="OFF"
           
             defaultChecked={PData?.DEFROSTSTATE}
             // checkedChildren={PData.DEFROSTSTATE == 1 ? 'ON' : 'OFF'}
             // unCheckedChildren={PData.DEFROSTSTATE == 1 ? 'ON' : 'OFF'}
             // style={{ backgroundColor: PData.DEFROSTSTATE == 1 ? 'green' : 'red' }}
           />
         </Form.Item>
         {/* <Form.Item
           label="Password"
           name="password2"
           rules={[
             {
               required: true,
               message: 'Please input your password!',
             },
           ]}
         >
           <Input.Password />
         </Form.Item> */}
         <Form.Item>
           <Button type="primary" htmlType="submit">
             SET
           </Button>
         </Form.Item>
       </Form>
         </>
        }
       
      </Space>
      {
         loading?<Spin size="large" tip="Please Wait Loading..."></Spin>:<Table columns={columns} dataSource={writabledata?.sort().reverse()} />
      }
    </>
  )

}
export default Test;