import React
  from 'react';
import MainPage from "./Components/MainPage";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
function App() {

  return (
    <div className='app'>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={
            <MainPage />
          }
          />
        </Routes>
      </BrowserRouter>
    </div>

  )

}
export default App;
