import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import 'antd/dist/antd.min.css';
import {Switch} from "antd";
// const base_ip = window.location.href.replace("http://", "").split(":")[0];
axios.defaults.baseURL = "https://api.smart-iam.com/api/gateway/cold-rush-api";
// axios.defaults.baseURL = "https://192.168.1.205:5003";

// axios.defaults.baseURL="http://" +base_ip+ ":5001"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
